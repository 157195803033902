import React from "react";
import './aboutsection.css'


const AboutSection = () => {

return (

<div>

{/* -- */}
<div className="about_flex_">

<img src="https://zanzeo.in/wp-content/uploads/2022/06/Ban12.png"></img>

<div>
<h1>About !</h1>
<h2>Offering the Finest Freelance  
Web Design & Development Solutions.</h2>
<p>
I run JSON Softech, 
a Freelance Web Developer  
specializing in creative and high-performance 
web solutions for small and mid-size businesses. 
As the sole proprietor, I focus on delivering 
personalized services, tailoring each 
project to meet the unique needs of my clients.</p>
</div>

</div>
{/* -- */}



</div>

)


}

export default AboutSection;