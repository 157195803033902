import React from "react";
import Navbar from "../components/navbar";
import Topnavbar from "../components/topnavbar";
import './home.css'
import SliderComponent from "../components/slider";
import AboutSection from "../components/aboutsection";
import Industries from "../components/industries";
import Header from "./header";
import ContactusForm from "../components/contactusform";
import Dashboard from '../Images/Dashboards.png'
import ChatApp from "./chatApp";


const Home = () => {

return (

<div>

<Topnavbar></Topnavbar>
<Navbar></Navbar>
{/* -- */}
<SliderComponent></SliderComponent>
{/* -- */}
<AboutSection></AboutSection>
<Industries></Industries>
{/* -- */}
<h1 className="h1_Dash">Dashboard Creation</h1>
<img src={Dashboard} className="Dash_board"></img>
<ContactusForm></ContactusForm>
<ChatApp></ChatApp>
<Header></Header>

</div>

)

}

export default Home;