import React from "react";
import './header.css'

const Header = () => {

return (

<div>
{/* --- */}
<header>

<div className="headerdiv_">

    <h1 style={{color : "white", fontSize : '2.7rem'}}>Json Softech</h1>
    <p>
        As a Freelancer, 
        I embody Json Softech's 
        reputation for excellence in 
        professional Website Development 
        and design. Specializing in 
        strategy Development and hosting, 
        I offer a one-stop solution for 
        your online success. Operated 
        solely by me, Jay, a dedicated 
        individual passionate about 
        delivering top-notch solutions, 
        I am committed to ensuring the 
        success of your website projects.
    </p>
</div>
{/* -- */}
<ul>
<h1>Get In Touch</h1>
<h2>Email & Mob:</h2>
<li><a href="">
    Jsonsoftech@gmail.com</a></li>
<li><a href=""> Mob : +91 9311071290</a></li>
</ul>
{/* --- */}
<ul>
<li><a href="index.html">Home</a></li>
<li><a href="About.html">About</a></li>
<li><a href="Services.html">Services</a></li>
<li><a href="Technologies.html">Technologies</a></li>
<li><a href="Pricing.html">Pricing</a></li>
<li><a href="Contact.html">Contact</a></li>
</ul>


<ul>
<li><a href="">Website Development</a></li>
<li><a href="">Static Website Development</a></li>
<li><a href="">Software Solution's</a></li>
<li><a href="">Web Hosting</a></li>
</ul>

</header>
{/* --- */}
</div>

)

}

export default Header;