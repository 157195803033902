import React from "react";
import Navbar from "../components/navbar";
import Topnavbar from "../components/topnavbar";
import Header from "./header";
import TechnologiesImg from '../Images/technologies.png'
import JavascriptLogo from '../Images/JavaScript.png';
import NodejsLogo from '../Images/node js.png';
import ReactJs from '../Images/React.js.png';
import Html5 from '../Images/Html.png';
import Css3 from '../Images/css3.png';
import MySQL from '../Images/mySQL.png';
import './technologies.css'
import ChatApp from "./chatApp";


const Technologies = () => {

return (

<div>

<Topnavbar></Topnavbar>
<Navbar></Navbar>

<div className="Tech_web">
    
<h1>Technologies We Use
</h1>
<p>Technologies I use include HTML, CSS, JavaScript, React, and Node.js, 
enabling efficient, <br></br> scalable, and visually engaging Web Solutions.
</p>


<div>
<span>
    <h2>JavaScript</h2>
    <img src={JavascriptLogo}></img></span>
<span>
<h2>Node.js</h2>
    <img src={NodejsLogo}></img></span>
<span>
<h2>React.js</h2>
    <img src={ReactJs}></img></span>
</div>

<div>
<span>
    <h2>HTML5</h2>
    <img src={Html5}></img></span>
<span>
<h2>CSS3</h2>
    <img src={Css3}></img></span>
<span>
<h2>MySQL</h2>
    <img src={MySQL}></img></span>
</div>

</div>
{/* --- */}


<div className="technology_c">

<h1>Software We use to Build 
    Your <br></br> Website & Software</h1>


<div className="techology_flex">

<div className="flex_main_con">
{/* -- */}
<div>
<h2>HTML5
</h2>
</div>
{/* --- */}
<div>
<h2>CSS3</h2>
</div>
{/* --- */}
<div>
<h2>React.JS
</h2>
</div>
{/* --- */}
</div>

<img src={TechnologiesImg} className="Tech_img"></img>

<div className="flex_main_con">
{/* -- */}
<div>
<h2>JavaScript
</h2>
</div>
{/* --- */}
<div>
<h2>Node.js 
</h2>
</div>
{/* --- */}
<div>
<h2>MySQL
</h2>
</div>
{/* --- */}
</div>
{/* - */}
</div>
{/* - */}
<div>
{/* - */}
</div>

{/* - */}
</div>

{/* -- */}

<ChatApp></ChatApp>
<Header></Header>

</div>

)


}

export default Technologies;