
import React from "react";
import Topnavbar from "../components/topnavbar";
import Navbar from "../components/navbar";
import ContactusForm from "../components/contactusform";
import Header from "./header";
import './contactus.css'
import contactUspage from '../Images/contact_us-Page.png';
import ChatApp from "./chatApp";

const Contactus = () => {

return (

<div>

<Topnavbar></Topnavbar>
<Navbar></Navbar>


<div className="Flex_contact_">

<section>
    
    <div>
    <i className="fa fa-phone"></i>
    <span>
    <h1>Contact Me
    </h1>
    <h2>+91 9311071290</h2>
    </span>
    </div>
    
    <div>
    <i className="fa fa-envelope"></i>
    <span><h1>Email
    </h1>
    <h2>Jsonsoftech@gmail.com</h2></span>
    </div>
</section>
{/* --- */}


<img src="https://img.freepik.com/free-vector/flat-man-illustration-customer-support_23-2148889244.jpg?t=st=1730365955~exp=1730369555~hmac=cfcb433ba9160f335eb138715a6651de8b2fe605180ba832f98c9ded1dd17859&w=996"></img>

</div>


<ContactusForm></ContactusForm>
<ChatApp></ChatApp>
<Header></Header>

</div>

)

}

export default Contactus;