import React from "react";
import './industries.css'


const Industries = () => {

return (

<div>
{/* -- */}

<div className="technology_c">

<h1>I Provide IT & Business Solutions
</h1>
<p>Tailored IT And Business Solution, Leveraging cutting-Edge <br></br>
Technologies To Enclave Efficiency Strealine, Process, And Drive
Sustainable Growth For Organanizations.
</p>


<div className="techology_flex">

<div className="flex_main_con">
{/* -- */}
<div>
<h2>Customer Support
</h2>
<p>24 X 7 <br></br>
Responsive Customer Assistance.</p>
</div>
{/* --- */}
<div>
<h2>API <br></br> Intregation
</h2>
<p>
Seamless API <br></br>  Integration..</p>
</div>
{/* --- */}
<div>
<h2>Business Solutions
</h2>
<p>
Streamlined Services for <br></br>
Business Efficiency</p>
</div>
{/* --- */}
</div>


<img src="https://zanzeo.in/wp-content/uploads/2022/06/Ban20.png"></img>

<div className="flex_main_con">
{/* -- */}
<div>
<h2>Website AMC
</h2>
<p>Yearly & Half Yearly <br></br>
Basis AMC<br></br> 
</p>
</div>
{/* --- */}
<div>
<h2>Static Website <br></br>
Development 
</h2>
<p>
Website Solutions That <br></br>
Work for You 
</p>
</div>
{/* --- */}
<div>
<h2>Custom Web <br></br> Development
</h2>
<p>
Interactive Website <br></br> 
With Tools.</p>
</div>
{/* --- */}
</div>
{/* ---------- */}

</div>

<div>

</div>

</div>

{/* -- */}
</div>

)

}

export default Industries;