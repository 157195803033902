import React from "react";
import './chatApp.css';
import emailjs from 'emailjs-com';
import { useState,ImgContactus } from "react";
import ChatIcon from '../Images/iconMessage.png';



const ChatApp = () => {

    const [showForm, setShowForm] = useState(false);
    
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        recipientEmail: '',  
        message: '',
      });
    
      const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      };
    
      const sendEmail = (e) => {
        e.preventDefault();
    
        const emailData = {
          to_email: formData.recipientEmail,  // Use the user's input
          name: formData.name,
          email: formData.email,
          message: formData.message,
          to_name: formData.name,
          reply_to: formData.email,
        };
    
        emailjs.send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID,
          process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
          emailData,
          process.env.REACT_APP_EMAILJS_USER_ID
        )
        .then((response) => {
          console.log('Email sent successfully!', response.status, response.text);
          setFormData({ name: '', email: '', recipientEmail: '', message: '' }); // Clear the form
        }, (err) => {
          console.error('Failed to send email. Error:', err);
        });
      };

      const handleUserChat = () => {
       
      }


return (

<div>
<img 
  className="message_icon"
  src={ChatIcon} // Keep this as the intended image source
  alt="Contact Icon"
  onClick={() => setShowForm(!showForm)} 
/>

      {/* Conditional Message Form */}
      {showForm && (
        <form onSubmit={sendEmail} className="chatApp_form">
          <div>        
            {/* <img src={ImgContactus} alt="Contact Us" /> */}
          </div>
          <div>
            <h1>Hi there! <br /> Contact Me</h1>
            <input
              type="text"
              name="name"
              placeholder="Your Name"
              value={formData.name}
              onChange={handleChange}
              required
            /> <br />
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              value={formData.email}
              onChange={handleChange}
              required
            /> <br />
            <input
              type="email"
              name="recipientEmail"
              placeholder="Recipient's Email"
              value={formData.recipientEmail}
              onChange={handleChange}
              required
            /> <br />
            <textarea
              name="message"
              placeholder="Your Message"
              value={formData.message}
              onChange={handleChange}
              required
            /> <br />
            <button type="submit">Send Message</button>
          </div>
        </form>
      )}

</div>

)


}

export default ChatApp;