import React from "react";
import Slider from "react-slick";
import Web_devImg from '../Images/web_development.png'
import './slider.css'


const SliderComponent = () => {

    const settings = {
        dots: true, 
        infinite: true, 
        speed: 500, 
        slidesToShow: 1, 
        slidesToScroll: 1, 
        autoplay: true, 
        autoplaySpeed: 3000, 
      };    
    
return (

<div>

{/* --- */}
<div className="flex_col_home">

<Slider {...settings} className="slider_div">
        <div>
          <p>CREATIVE & INNOVATIVE</p>
          <h1>As a Freelance Web Developer,I Fuel your Business Growth.</h1>
        </div>
        {/* --------- */}
        <div>
          <h1>Digital Transformation Solutions for Software Development</h1>
        </div>
        {/* ---------- */}
        <div>
          <h1>A Complete Solution Of your Business</h1>
        </div>
        {/* --------- */}
      </Slider>

      <img src={Web_devImg}></img>

</div>


</div>

)


}

export default SliderComponent;