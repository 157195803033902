import React from "react";
import Navbar from "../components/navbar";
import Topnavbar from "../components/topnavbar";
import Header from "./header";
import ChatApp from "./chatApp";


const About = () => {

return (

<div>

<Topnavbar></Topnavbar>
<Navbar></Navbar>

{/* AboutSection.jsx--Page */}

<div className="about_flex_">

<img src="https://img.freepik.com/free-vector/single-page-application-abstract-concept-illustration-spa-web-page-web-development-trend-app-inside-browser-dynamically-rewriting-page-responsive-website-creation_335657-899.jpg?t=st=1730281295~exp=1730284895~hmac=8e8bc702a1cfe7a761e650be04af833d7f835350458b77b13d2c903f1721db5d&w=740"></img>

<div>
<h1>About !</h1>
<h2>Offering the Finest Freelance  
Web Design & Development Solutions.</h2>
<p>
I run JSON Softech, 
a Freelance Web Developer  
specializing in creative and high-performance 
web solutions for small and mid-size businesses. 
As the sole proprietor, I focus on delivering 
personalized services, tailoring each 
project to meet the unique needs of my clients.</p>
</div>

</div>
{/* -- */}
<ChatApp></ChatApp>
<Header></Header>


</div>

)


}

export default About;