import React from "react";
import './Pricing.css'
import Topnavbar from "../components/topnavbar";
import Navbar from "../components/navbar";
import Header from "./header";
import ChatApp from "./chatApp";


const Pricing = () => {

return (

<div>

<Topnavbar></Topnavbar>
<Navbar></Navbar>

{/* --- */}
<div className="Webs_itePrice">
<h2>Flexible Pricing Plans</h2>
<h1>Website Designing Packages</h1>

<div className="Web_flex">
{/* --1st Div */}
<section>

<div className="Div_webPrice Div_fnd">
    <p>Standard Plan</p>
    <h1>₹ 2999</h1>
    <p>Features Includes</p>
</div>
{/* -- */}
<ul>
<li>5 Pages</li>
<li>Basic Design</li>
<li>One Month of Support</li>
<li>WhatsApp Message Button</li>
</ul>

</section>
{/* ---2nd Div */}

<section>

<div className="Div_webPrice Div_snd">
    <p>Standard Plan</p>
    <h1>₹ 6990</h1>
    <p>Features Includes</p>
</div>
{/* -- */}

<ul>
<li>10 Pages</li>
<li>Customizable Template Design</li>
<li>UI/UX Enhancements</li>
<li>Interactive Elements</li>
<li>1 Month Support</li>
<li>Integrated Call Button</li>
<li>WhatsApp Communication Button</li>
<li>Messaging Application Integration</li>
</ul>

</section>
{/* ---3rd Div */}


<section>

<div className="Div_webPrice Div_trd">
    <p>Standard Plan</p>
    <h1>₹ 9990</h1>
    <p>Features Includes</p>
</div>
{/* -- */}
<ul>

<li>10 Pages</li>
<li>Customizable Template Design</li>
<li>Enhanced Visuals</li>
<li>UI/UX Enhancements</li>
<li>Interactive Elements</li>
<li>Responsive and Fast Loading</li>
<li>1 Month Support</li>
<li>Integrated Call Button</li>
<li>WhatsApp Communication Button</li>
<li>Messaging Application Integration</li>
</ul>

</section>

</div>
{/* -- */}

</div>

<ChatApp></ChatApp>
<Header></Header>

</div>

)


}

export default Pricing;